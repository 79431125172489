/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import React from 'react';

export const CUSTOM_STORY_ATTRIBUTE = 'customStory';

export const withStoryPageContent = async (Component, subPageType) => {
  const StoryComponent = await getStoryComponent(subPageType);
  const renderStoryPageContent = props => {
    const { storyTemplate, storyPageType } = findStoryTemplate({ story: props.story, config: props.config });
    const storyType = storyTemplate || 'text';
    const storyField = ['text-story', 'photo-story', 'video-story', 'liveBlog-story', 'listicle-story'].includes(
      `${camelCase(storyType)}-story`
    )
      ? 'story'
      : 'customStory';
    const updatedProps = {
      ...props,
      storyStoreField: storyField,
      storyPageType: storyPageType
    };
    return <StoryComponent {...updatedProps} />;
  };
  const Comp = props => {
    return <Component renderStoryPageContent={renderStoryPageContent} {...props} />;
  };
  return Comp;
};

export const getStoryComponent = async subPageType => {
  /*
    NOTE: The webpack chunk names are and should be absolute,
    they are consumed by render-layout.js via pattern match on runtime.
  */
  switch (subPageType) {
    case 'text':
      return (
        await import(
          /* webpackChunkName: "text-story-template-chunk" */
          '../components/story-templates/text-story'
        )
      ).default;
    case 'live-blog':
      return (
        await import(
          /* webpackChunkName: "live-blog-story-template-chunk" */
          '../components/story-templates/live-blog'
        )
      ).default;
    case 'video':
      return (
        await import(
          /* webpackChunkName: "video-story-template-chunk" */
          '../components/story-templates/video-story'
        )
      ).default;
    case 'photo':
      return (
        await import(
          /* webpackChunkName: "photo-story-template-chunk" */
          '../components/story-templates/photo-story'
        )
      ).default;
    case 'listicle':
      return (
        await import(
          /* webpackChunkName: "listicle-story-template-chunk" */
          '../components/story-templates/listicle-story'
        )
      ).default;
    case 'other':
    default:
      return (
        await import(
          /* webpackChunkName: "custom-story-template-chunk" */
          '../components/story-templates/custom-story'
        )
      ).default;
  }
};

export const findStoryTemplate = ({ story, config }) => {
  const storyTemplate = get(story, ['story-template']) || '';

  const storyPageType = `${camelCase(storyTemplate)}-story`;
  const storyBaseType = kebabCase(
    get(config, ['pagebuilder-config', CUSTOM_STORY_ATTRIBUTE, storyPageType, 'storyType'], '')
  );
  return {
    storyTemplate,
    storyPageType,
    storyBaseType
  };
};
